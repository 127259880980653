import { ErrorHandler, Input, OnChanges, OnInit } from '@angular/core';
import { PageOptions } from '../../core/models';
import { Entity, Journal } from '../models';
import { JournalService } from '../services/journal.service';

export class JournalListBaseComponent implements OnInit, OnChanges {

  @Input()
  entity: Entity;

  @Input()
  refreshSeconds = 0;

  @Input()
  options: PageOptions;

  @Input()
  params: any = {};

  items: Journal[] = [];

  isProcessing = false;

  afterInitialization: () => void;
  afterProcessing: () => void;

  constructor(
    private journalService: JournalService,
    private errorHandler: ErrorHandler
  ) { }
  ngOnInit(): void {
    this.isProcessing = true;

    this.getData();
  }

  ngOnChanges(): void {
    this.isProcessing = true;
    this.getData();
  }

  getData() {

    if (this.entity) {
      if (this.entity.id) {
        this.params['entity_id'] = this.entity.id;
      }
      if (this.entity.type) {
        this.params['entity_type'] = this.entity.type;

      }
    }

    this.options = this.options || new PageOptions({
      limit: 10
    });
    this.journalService.search(this.params, this.options).subscribe((p) => {
      this.items = p.items;
      this.isProcessing = false;
      if (this.afterProcessing) {
        this.afterProcessing();
      }
    }, (err) => {
      this.isProcessing = false;
      this.errorHandler.handleError(err.message || err);
    });

    if (this.refreshSeconds) {
      setTimeout(() => {
        this.getData();
      }, this.refreshSeconds * 1000);
    }
  }
}
