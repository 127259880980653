import { Component } from '@angular/core';
import { UxService } from 'src/app/core/services/ux.service';
import { JournalListBaseComponent } from '~/app/open-age/insight/components/journal-list.base.component';
import { JournalService } from '~/app/open-age/insight/services/journal.service';

@Component({
  selector: 'insight-journal-list',
  templateUrl: './journal-list.component.html',
  styleUrls: ['./journal-list.component.css']
})
export class JournalListComponent extends JournalListBaseComponent {
  constructor(
    journalService: JournalService,
    errorHandler: UxService
  ) {
    super(journalService, errorHandler);
   }
}
