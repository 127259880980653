import { ModelBase } from '../../core/models/model-base.model';

export class Organization extends ModelBase {
  name: string;
  calendarID: string;
  authToken: string;
  timezoneOffset: Number
  meta: any;

  constructor(obj?: any) {
    super();

    if (!obj) {
      return;
    }

    this.id = obj.id;
    this.code = obj.code;
    this.calendarID = obj.calendarID;
    this.authToken = obj.authToken;
    this.name = obj.name;
    this.timezoneOffset = Number(obj.timezoneOffset);
    this.status = obj.status;
    this.timeStamp = obj.timeStamp;
    this.meta = obj.meta || {}
  }
}
