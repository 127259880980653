
import { User } from '.';
import { Employee } from './employee.model';
import { Organization } from './organization.model';
import { Profile } from './profile.model';
import { Student } from './student.model';
import { Tenant } from './tenant.model';

export class Role {
  id: string;
  code: string;
  email: string;
  token: string;
  status: string;
  type: any;
  user: User;
  profile: Profile;
  timeStamp: Date;
  employee?: Employee;
  student?: Student;
  organization?: Organization;
  tenant?: Tenant;
  permissions: string[] = [];

  constructor(obj?: any) {

    if (!obj) {
      return;
    }

    this.id = obj.id;
    this.token = obj.token;
    this.email = obj.email;
    this.type = obj.type;
    this.status = obj.status;
    this.timeStamp = obj.timeStamp;

    if (obj.profile) {
      this.profile = new Profile(obj.profile);
    } else {
      this.profile = new Profile({});
    }

    if (obj.user) {
      this.user = new User(obj.user);
    }

    if (obj.employee) {
      this.employee = new Employee(obj.employee);
      this.code = this.employee.code;
      if (obj.employee.profile) {
        this.profile = new Profile(obj.employee.profile);
      }
    }

    if (obj.student) {
      this.student = new Student(obj.student);
      this.code = this.student.code;
      if (obj.student.profile) {
        this.profile = new Profile(obj.student.profile);
      }
    }

    if (obj.organization) {
      this.organization = new Organization(obj.organization);
    }

    if (obj.tenant) {
      this.tenant = new Tenant(obj.tenant);
    }

    this.permissions = [];
    if (obj.permissions) {
      obj.permissions.forEach((permission) => {
        this.permissions.push(permission);
      });
    }
  }
}
