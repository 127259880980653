import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule, MatIconModule, MatMenuModule, MatToolbarModule, MatSnackBarModule, MatListModule, MatDialogModule, MatFormFieldModule, MatInputModule, MatTabsModule, MatTooltipModule, MatSidenavModule, MatCheckboxModule, MatTableModule } from '@angular/material';
import { PracticesComponent } from './practices/practices.component';
import { PracticeRoutingModule } from './practice-routing.module';
import { PracticeNewComponent } from './practice-new/practice-new.component';
import { PracticeComponent } from './practice/practice.component';
import { PracticeViewComponent } from './practice-view/practice-view.component';
import { OaDirectoryModule } from '../open-age-ng/directory/oa-directory.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { SelectSurveyCategoryDialogComponent } from './dialog/select-survey-category/select-survey-category.component';
import { WelcomeModule } from '../open-age/welcome/welcome.module';
import { PatientListComponent } from './components/patient-list/patient-list.component';
import { EmployeeListComponent } from './components/practitioner-List/practitioner-list.component';

@NgModule({
  declarations: [
    PracticesComponent,
    PracticeComponent,
    PracticeNewComponent,
    PracticeViewComponent,
    PatientListComponent,
    SelectSurveyCategoryDialogComponent,
    EmployeeListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatTableModule,
    MatTooltipModule,
    MatSidenavModule,
    OaDirectoryModule,
    PracticeRoutingModule,
    SharedModule,
    MatCheckboxModule,
    WelcomeModule
  ], entryComponents: [SelectSurveyCategoryDialogComponent]
})
export class PracticeModule { }
