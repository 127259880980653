import { Component, ErrorHandler, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { PagerModel } from '~/app/open-age/core/structures';
import { UxService } from '~/app/core/services';
import { Log, User } from '../models';
import { LogService } from '../services/log.service';

export class LogListBaseComponent extends PagerModel<Log> implements OnInit, OnChanges {

  @Input()
  user: string | User;

  @Input()
  message: string;

  constructor(
    private api: LogService,
    public uxService: UxService,
    errorHandler: ErrorHandler
  ) {
    super({
      api,
      errorHandler,
      filters: [
        'user', 'message'
      ]
    });
  }

  ngOnInit() {
    // this.refresh();
  }

  ngOnChanges() {
    this.refresh();
  }

  refresh() {
    this.filters.reset(false);

    if (this.user) {
      this.filters.set('user', this.user);
    }

    if (this.message) {
      this.filters.set('message', this.message);
    }

    this.fetch();
  }

}
