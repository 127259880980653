import { Component, OnInit, Output, EventEmitter, Input, ErrorHandler } from '@angular/core';
import { Role, User } from '~/app/open-age/directory/models';
import { RoleService, IInputValidator } from '~/app/open-age/core/services';
declare const gapi: any;
@Component({
  selector: 'auth-google-login-button',
  templateUrl: './google-login-button.component.html',
  styleUrls: ['./google-login-button.component.css'],
})
export class GoogleLoginButtonComponent implements OnInit {

  @Output()
  success: EventEmitter<Role> = new EventEmitter();

  @Output()
  failure: EventEmitter<Error> = new EventEmitter();

  @Input()
  isProcessing = false;

  private scope = [
    // 'profile',
    'email',
    // 'https://www.googleapis.com/auth/plus.me',
    // 'https://www.googleapis.com/auth/contacts.readonly',
    // 'https://www.googleapis.com/auth/admin.directory.user.readonly'
  ].join(' ');

  public auth2: any;
  code: string;
  error: string;

  constructor(
    private auth: RoleService,
    private errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id: this.auth.currentTenant().meta.google.webClientId,
        cookiepolicy: 'single_host_origin',
        scope: this.scope
      });
      this.attachSignin(document.getElementById('googleBtn'));
    });
  }

  public attachSignin(element) {
    this.auth2.attachClickHandler(element, {},
      (googleUser) => {
        this.isProcessing = true;
        // let profile = googleUser.getBasicProfile();
        let token = googleUser.getAuthResponse().id_token
        // console.log('Token || ' + googleUser.getAuthResponse().id_token);
        // console.log('ID: ' + profile.getId());

        this.auth.authSuccess(token, "google").subscribe((role: Role) => {
          this.isProcessing = false;
          this.success.next(role);
        }, (err) => {
          this.isProcessing = false;
          const error = new Error("User does not exists");
          error.name = err.message || "User does not exists";
          error.message = err.message || "User does not exists";

          if (error.name === 'PASSWORD_INVALID') {
            error.message = 'wrong password';
          }

          this.error = error.message;

          this.failure.next(error);
          this.errorHandler.handleError(error.message || "User does not exists");
        });
      }, function (error) {
        console.log(JSON.stringify(error, undefined, 2));
      });
  }
}

