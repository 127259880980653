import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { UxService } from 'src/app/core/services/ux.service';
import { EmployeeListBaseComponent } from '../../../open-age/directory/components/employee-list-base.component';
import { EmployeeService } from '../../../open-age/directory/services/employee.service';

@Component({
  selector: 'practitioner-list',
  templateUrl: './practitioner-list.component.html',
  styleUrls: ['./practitioner-list.component.css'],
})
export class EmployeeListComponent extends EmployeeListBaseComponent {
  @Input()
  view: 'table' | 'list' | 'grid' = 'table';

  @Input()
  organization: string;
  dialogRef: any;

  constructor(public dialog: MatDialog,
    employeeService: EmployeeService,
    private uxService: UxService

  ) {
    super(employeeService, uxService);
  }

  onRemove(item): void {
    this.uxService.onConfirm().subscribe(() => {
      this.remove(item);
      this.uxService.showInfo('Deleted');
    });
  }

  public refresh(): void {
    this.fetch().subscribe();
  }

}
