import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericApi } from '~/app/open-age/core/services/generic-api';
import { environment } from '../../../../environments/environment';
import {
  Employee,
  Organization,
  Role,
  Service,
  Session,
  Student,
  Tenant,
  User
} from '../../directory/models';
import { Profile } from '../../directory/models/profile.model';
import { IAuth } from './auth.interface';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService implements IAuth {

  private _organization: Organization;
  private _tenant: Tenant;
  private _role: Role;
  private _user: User;
  private _authApi: GenericApi<any>;
  private _rolesApi: GenericApi<Role>;
  private _sessionsApi: GenericApi<Session>;

  private _organizationSubject = new Subject<Organization>();
  private _tenantSubject = new Subject<Tenant>();
  private _roleSubject = new Subject<Role>();
  private _userSubject = new Subject<User>();

  organizationChanges = this._organizationSubject.asObservable();
  tenantChanges = this._tenantSubject.asObservable();
  roleChanges = this._roleSubject.asObservable();
  userChanges = this._userSubject.asObservable();

  newUser(user: any) {
    this._userSubject.next(user);
  }

  refreshIdle = false
  private _refreshIdle = new Subject<Boolean>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private localDb: LocalStorageService
  ) {

    this._authApi = new GenericApi(this.http, 'directory', {
      collection: 'users',
      auth: this
    });
    this._rolesApi = new GenericApi(this.http, 'directory', {
      collection: 'roles',
      auth: this
    });
    this._sessionsApi = new GenericApi(this.http, 'directory', {
      collection: 'sessions',
      auth: this
    });
  }

  private _getDirectoryUrl() {
    const tenant = this.currentTenant();

    if (!tenant) {
      return;
    }
  }

  toogleIdle() {
    this.refreshIdle = !this.refreshIdle
    this._refreshIdle.next(this.refreshIdle)
  }

  currentIdle(): Observable<Boolean> {
    return this._refreshIdle.asObservable();
  }

  private _defaultRole(user: User): Role {
    // user.roles.length
    let role = user.roles.find((item) => !item.organization);
    if(role) {
      return role
    } else if(user.roles.length) {
      role = user.roles[0]
      return role
    }
  }
  // user:any;
  private _extractRole(user: User): Role {
    let defaultRole = this._defaultRole(user);

    const roleToken = (this.localDb.get('role') || {}).token;

    if (!roleToken) {
      if (user.roles.length === 1) {
        return user.roles[0];
      } else if (user.roles.length > 1) {
        defaultRole = user.roles[0];
        // user.roles.forEach((item) => {
        //   if (!!item.organization) {
        //     defaultRole = item;
        //   }
        // });
      }

      return defaultRole;
    }

    const role = user.roles.find((item) => item.token === roleToken);

    // if (!role) {
    //   this.localDb.remove('role');
    // } else {
    //   this.localDb.update('role', role);
    // }
    return role;
  }

  private _setRole(role: Role) {
    this._role = role;

    if (role) {
      role.permissions.push('user');
      if (role.employee) {
        role.permissions.push('organization.employee');
      } else if (role.student) {
        role.permissions.push('organization.student');
      }
      this.localDb.update('role', role);
      this._setOrganization(role.organization);
    } else {
      this.localDb.remove('role');
    }
    this._roleSubject.next(this._role);
    return role;
  }

  private _setUser(user: User) {
    this._user = user;
    this.localDb.update('user', this._user);
    this._userSubject.next(this._user);
    return user;
  }

  private _setTenant(tenant: Tenant) {
    this._tenant = tenant;
    if (tenant && environment.services && environment.services.length) {
      tenant.services = environment.services.map((s) => new Service(s));
    }
    this.localDb.update('tenant', tenant);
    // this._setOrganization(null);
    this._tenantSubject.next(this._tenant);
    return tenant;
  }

  private _setOrganization(item: Organization) {
    this._organization = item;
    this.localDb.update('organization', item);
    this._organizationSubject.next(this._organization);
    return item;
  }

  signup(userId: string): Observable<any> {
    const model: any = {
    };

    // tslint:disable-next-line:max-line-length
    if (userId.match(/^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|glass|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i)) {
      model.email = userId;
    } else if (userId.match(/^\d{10}$/)) {
      model.phone = userId;
    } else if (userId.match(/^(\+\d{1,3}[- ]?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)) {
      model.phone = userId;
    } else if (userId.match(/^(\+\d{1,3}[- ]?)?\(?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/)) {
      model.phone = userId;
    } else {
      throw new Error('mobile or email is required');
    }

    return this._authApi.create(model);
  }

  sendOtp(email: string, mobile: string, code: string): Observable<any> {
    return this._authApi.post({ email, mobile, code }, 'resend');
  }

  exists(identity: string, type?: string): Observable<any> {

    if (!type) {
      // tslint:disable-next-line:max-line-length
      if (identity.match(/^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|glass|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i)) {
        type = 'email';
      } else if (
        identity.match(/^\d{10}$/) ||
        identity.match(/^(\+\d{1,3}[- ]?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)) {
        type = 'mobile';
      } else {
        type = 'code';
      }
    }

    return this._authApi.get(`exists?${type}=${identity}`);
  }

  verifyPassword(email: string, mobile: string, code: string, employeeCode, studentCode: string, password: string): Observable<Role> {
    return this._authApi.post({ email, mobile, code, employee: { code: employeeCode }, student: { code: studentCode }, password }, 'signIn').pipe(map((data) => {
      const user = this._setUser(new User(data));
      const role = this._extractRole(user);
      this._setRole(role);
      return role;
    }));
  }

  authSuccess(token: string, provider: string): Observable<Role> {
    return this._authApi.get(`auth/${provider}/success?code=${token}`).pipe(map((data) => {
      let rawUser = new User(data)
      let roles = []
      if (rawUser && rawUser.roles && rawUser.roles.length) {
        for (const role of rawUser.roles) {
          if (role.employee && role.employee.type == 'superadmin' || (role.type && role.type.code == 'tenant.admin')) {
            roles.push(role)
          }
        }
      }
      rawUser.roles = roles
      if (rawUser && rawUser.roles && rawUser.roles.length && !roles.length) {
        throw new Error('No Admin Role found for User')
      }
      if (!roles.length) {
        throw new Error('User does not exists')
      }
      const user = this._setUser(rawUser);
      const role = this._extractRole(user);
      this._setRole(role);
      return role;
    }));
  }

  setPassword(password): Observable<any> {
    return this._authApi.post({ password }, `resetPassword`);
  }

  initPassword(model: any, otp: string, password: string): Observable<any> {
    return this._authApi.post({
      id: model.id || model,
      profile: model.profile,
      otp,
      password
    }, `setPassword`).pipe(map((data) => {
      const user = this._setUser(new User(data));
      const role = this._extractRole(user);
      this._setRole(role);
      return role;
    }));
  }

  verifyOtp(id: string, otp: string): Observable<Role> {
    return this._authApi.post({ id, otp }, 'confirm').pipe(map((data) => {
      const user = this._setUser(new User(data));
      const role = this._extractRole(user);
      this._setRole(role);
      return role;
    }));
  }

  refreshUser() {
    const currentUser = this.currentUser();
    if (currentUser) {

      const defaultRole = this._defaultRole(currentUser);
      const headers = [{
        key: 'authorization',
        value: defaultRole.token,
      }];
      const api = new GenericApi(this.http, 'directory', { auth: this, collection: 'users', headers, noRefresh: true });

      api.get('my').subscribe((data) => {
        const user = this._setUser(new User(data));
        const role = this._extractRole(user);
        this._setRole(role);
      });
    }
  }

  currentRole(): Role {
    if (this._role) {
      return this._role;
    }

    const user = this.currentUser();

    if (!user) {
      return null;
    }

    const role = this._extractRole(user);
    return this._setRole(role);
  }

  currentUser(): User {
    if (this._user) {
      return this._user;
    }

    const savedUser = this.localDb.get('user');

    let user: User = null;

    if (savedUser) {
      user = this._setUser(new User(savedUser));
    }

    return user;
  }

  currentTenant(): Tenant {
    if (this._tenant) {
      return this._tenant;
    }

    let tenant = this.localDb.get('tenant');

    if (!tenant && environment.code) {
      tenant = new Tenant(environment.code);
    }

    return this._setTenant(tenant);
  }

  currentOrganization(): Organization {
    if (this._organization) {
      return this._organization;
    }

    let item = this.localDb.get('organization');

    // TODO
    // const orgCode = route.snapshot.queryParams['organization-code'];
    if (!item && environment.organization) {
      item = new Organization(environment.organization);
    }

    if (item) {
      return this._setOrganization(item);
    }

    return null;
  }

  addRole(role: Role) {
    const user = this.currentUser();
    if (!user) { return null; }
    let newRole = user.roles.find((item) => item.token === role.token);
    if (!newRole) {
      user.roles.push(role);
      newRole = role;
      this.localDb.update('user', user);
    }
    return newRole;
  }
  getRole() {
    return (this.localDb.get('role') || {}).token;
  }

  setRole(role?: Role) {
    const user = this.currentUser();
    if (!user) { return; }

    if (!role) {
      role = this._defaultRole(user);
    }

    const newRole = user.roles.find((item) => item.token === role.token);
    if (newRole) {
      this._setRole(newRole);
    }
    this.refreshUser()
    return newRole;
  }

  setRoleKey(roleToken: string): Observable<Role> {
    const api = new GenericApi(this.http, 'directory', {
      collection: 'users',
      auth: this,
      headers: [{
        key: 'authorization',
        value: roleToken,
      }],
    });
    return api.get('my').pipe(map((data) => {
      const user = this._setUser(new User(data));
      const newRole = user.roles.find((item) => item.token === roleToken);
      if (newRole) {
        this._setRole(newRole);
      }
      return newRole;
    }));
  }

  setTenant(item: Tenant): Tenant {
    return this._setTenant(item);
  }

  setOrganization(item: Organization): Organization {
    return this._setOrganization(item);
  }

  newRole(profile: Profile, type: 'individual' | 'employee' | 'student', organization?: Organization): Observable<Role> {
    const role = new Role();
    role.organization = this.currentOrganization() || organization;

    switch (type) {
      case 'individual':
        role.profile = profile;
        break;

      case 'student':
        role.student = new Student();
        role.student.profile = profile;
        break;

      case 'employee':
        role.employee = new Employee();
        role.employee.profile = profile;
        break;

    }

    return this._rolesApi.create(role).pipe(map((newRole) => {
      const user = this.currentUser();
      user.roles.push(newRole);
      this._setUser(user);
      this._setRole(newRole);
      return newRole;
    }));
  }

  joinAsEmployee(employee: Employee, organization?: Organization): Observable<Role> {
    const role = new Role();
    role.organization = this.currentOrganization() || organization;
    role.employee = employee;

    return this._rolesApi.create(role).pipe(map((newRole) => {
      this.currentUser().roles.push(newRole);
      return newRole;
    }));
  }

  joinAsStudent(student: Student, organization?: Organization): Observable<Role> {
    const role = new Role();
    role.organization = this.currentOrganization() || organization;
    role.student = student;

    return this._rolesApi.create(role).pipe(map((newRole) => {
      this.currentUser().roles.push(newRole);
      return newRole;
    }));
  }

  enroll(organization: Organization, student: Student): Observable<Role> {
    if (environment.organization) {
      organization = new Organization(environment.organization);
    }

    const role = new Role();
    role.organization = organization;
    role.student = student;

    return this._rolesApi.create(role).pipe(map((newRole) => {
      this.currentUser().roles.push(newRole);
      return newRole;
    }));
  }

  createSession(): Observable<Session> {
    const session = new Session();
    session.app = 'browser';
    return this._sessionsApi.create(session);
  }

  getSession(id: string): Observable<Session> {
    return this._sessionsApi.get(id).pipe(map((data: Session) => {
      if (data.user) {
        const user = this._setUser(data.user);
        const role = this._extractRole(user);
        this._setRole(role);
      }
      return data;
    }));
  }

  currentSession(): Session {
    return null;
  }

  logout() {
    const tenant = this.localDb.get('tenant');
    const organization = this.localDb.get('organization');

    this.localDb.clear();
    // Preserve this
    this.localDb.update('tenant', tenant);
    this.localDb.update('organization', organization);

    // this._roleSubject.next(null);
    // this._userSubject.next(null);
    // this._role = new Role(null);
    location.reload();

  }

  hasPermission(permissions: string | string[]): boolean {
    if (!permissions || Array.isArray(permissions) && !permissions.length) {
      return true; // every role has blank permission
    }

    const currentRole = this.currentRole();
    if (!currentRole) { return false; }

    if (!currentRole.permissions.length) { return false; }

    if (typeof permissions === 'string') {
      return !!currentRole.permissions.find((item) => item.toLowerCase() === permissions.toLowerCase());
    }

    for (const permission of permissions) {
      const value = currentRole.permissions.find((item) => item.toLowerCase() === permission.toLowerCase());
      if (value) {
        return true;
      }
    }

    return false;
  }
}
