import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { errorHandler } from '@angular/platform-browser/src/browser';
import { UxService } from '~/app/core/services/ux.service';
import { ValidationService } from '~/app/core/services/validation.service';
import { RoleService } from '~/app/open-age/core/services';
import { FileUploaderBaseComponent } from '~/app/open-age/drive/components/file-uploader-base.component';
import { DocsService } from '~/app/open-age/drive/services';
import { FileUploaderZoneComponent } from '../file-uploader-zone/file-uploader-zone.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-file-uploader-dialog',
  templateUrl: './file-uploader-dialog.component.html',
  styleUrls: ['./file-uploader-dialog.component.css']
})
export class FileUploaderDialogComponent extends FileUploaderBaseComponent {

  static instancesCount = 0;

  inputId: string;

  constructor(
    auth: RoleService,
    fileService: DocsService,
    errorHandler: UxService,
    validator: ValidationService,
    public dialogRef: MatDialogRef<FileUploaderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    sanitizer: DomSanitizer
  ) {
    super(auth, fileService, errorHandler, validator, sanitizer);
    this.inputId = `file-uploader-input-${FileUploaderZoneComponent.instancesCount++}`;
    this.types = ['video/mp4', 'video/mov', 'video/quicktime'];
    this.closeDialog = () => {
      this.dialogRef.close();
    };
  }

  close() {
    this.dialogRef.close();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if (event.target && event.target.value != "") {
      let newval = Number(event.target.value + event.key)
      if (newval > 500) {
        return false
      }
    }
    return true;

  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
