import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule, MatSlideToggleModule, MatCheckboxModule } from '@angular/material';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatDatepickerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatNativeDateModule,
  MatSelectModule,
  MatTabGroup,
  MatTabGroupBase,
  MatTableModule,
  MatTabsModule,
} from '@angular/material';
import { DirectoryModule } from 'src/app/open-age/directory/directory.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { OaAmsModule } from '../ams/oa-ams.module';
import { OaCarModule } from '../car/oa-car.module';
import { AddressEditorComponent } from './address-editor/address-editor.component';
import { BatchPickerComponent } from './batch-picker/batch-picker.component';
import { BiometricEditorComponent } from './biometric-editor/biometric-editor.component';
import { ContractorNewComponent } from './contractor-new/contractor-new.component';
import { ContractorsListComponent } from './contractors-list/contractors-list.component';
import { CoursePickerComponent } from './course-picker/course-picker.component';
import { DepartmentListComponent } from './department-list/department-list.component';
import { DepartmentNewComponent } from './department-new/department-new.component';
import { DepartmentPickerComponent } from './department-picker/department-picker.component';
import { DesignationListComponent } from './designation-list/designation-list.component';
import { DesignationNewComponent } from './designation-new/designation-new.component';
import { DesignationPickerComponent } from './designation-picker/designation-picker.component';
import { DetailsEditorComponent } from './details-editor/details-editor.component';
import { DivisionListComponent } from './division-list/division-list.component';
import { DivisionNewComponent } from './division-new/division-new.component';
import { DivisionPickerComponent } from './division-picker/division-picker.component';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { EmployeePickerComponent } from './employee-picker/employee-picker.component';
import { EmployeeSelectorComponent } from './employee-selector/employee-selector.component';
import { EmployeeSummaryComponent } from './employee-summary/employee-summary.component';
import { EmploymentEditorComponent } from './employment-editor/employment-editor.component';
import { EmploymentViewerComponent } from './employment-viewer/employment-viewer.component';
import { OrganizationCreateComponent } from './organization-create/organization-create.component';
import { OrganizationDetailsComponent } from './organization-details/organization-details.component';
import { OrganizationListComponent } from './organization-list/organization-list.component';
import { OrganizationSummaryComponent } from './organization-summary/organization-summary.component';
import { PersonalEditorComponent } from './personal-editor/personal-editor.component';
import { ResetPasswordEditorComponent } from './reset-password-editor/reset-password-editor.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SalaryEditorComponent } from './salary-editor/salary-editor.component';
import { StudentAddressEditorComponent } from './student-address-editor/student-address-editor.component';
import { StudentEnrollmentComponent } from './student-enrollment/student-enrollment.component';
import { StudentListComponent } from './student-list/student-list.component';
import { RoleListComponent } from './role-list/role-list.component';

const components = [
  EmployeeListComponent,
  EmploymentEditorComponent,
  EmploymentViewerComponent,
  PersonalEditorComponent,
  EmployeeSelectorComponent,
  OrganizationListComponent,
  OrganizationDetailsComponent,
  OrganizationCreateComponent,
  OrganizationSummaryComponent,
  EmployeePickerComponent,
  DesignationPickerComponent,
  DepartmentPickerComponent,
  DivisionPickerComponent,
  DepartmentListComponent,
  DesignationListComponent,
  DivisionListComponent,
  DesignationNewComponent,
  DivisionNewComponent,
  DepartmentNewComponent,
  AddressEditorComponent,
  DetailsEditorComponent,
  BiometricEditorComponent,
  ContractorsListComponent,
  ContractorNewComponent,
  ResetPasswordComponent,
  SalaryEditorComponent,
  ResetPasswordEditorComponent,
  EmployeeSummaryComponent,
  StudentListComponent,
  StudentAddressEditorComponent,
  StudentEnrollmentComponent,
  BatchPickerComponent,
  CoursePickerComponent,
  RoleListComponent
];
const thirdPartyModules = [
  MatIconModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatAutocompleteModule,
  MatSelectModule,
  MatCardModule,
  MatListModule,
  MatTableModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatDialogModule,
  MatTabsModule,
  MatSlideToggleModule,
  MatCheckboxModule
];
const services = [];
const guards = [];
const pipes = [];

@NgModule({
  imports: [
    CommonModule,
    DirectoryModule,
    // OaAmsModule,
    SharedModule,
    OaCarModule,
    ...thirdPartyModules,
  ],
  declarations: [...components, ...pipes],
  exports: [...components, ...pipes],
  providers: [...services, ...guards],
  entryComponents: [ResetPasswordComponent],
})
export class OaDirectoryModule { }
