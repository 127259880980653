import { Component, OnInit } from '@angular/core';
import { UxService } from '~/app/core/services/ux.service';
import { ValidationService } from '~/app/core/services/validation.service';
import { RoleService } from '~/app/open-age/core/services';
import { Organization } from '~/app/open-age/directory/models';
import { Organization as WelcomeOrganization } from '~/app/open-age/welcome/models/organization.model';
import { OrganizationService as WelcomeOrganizationService } from '~/app/open-age/welcome/services';
import { DirectoryRoleService, DivisionService } from '~/app/open-age/directory/services';
import { Link } from '~/app/open-age/core/structures';
import { NavService } from '~/app/core/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-practice-new',
  templateUrl: './practice-new.component.html',
  styleUrls: ['./practice-new.component.css']
})
export class PracticeNewComponent implements OnInit {

  practice: Organization;
  welcomePractice: WelcomeOrganization = new WelcomeOrganization({})
  isProcessing: boolean;
  id: string;
  isCurrent = true;
  page: Link;
  states = ["Alabama (AL)", "Alaska (AK)", "Arizona (AZ)", "Arkansas (AR)", "California (CA)",
    "Colorado (CO)", "Connecticut (CT)", "Delaware (DE)", "District of Columbia (DC)",
    "Florida (FL)", "Georgia (GA)", "Hawaii (HI)", "Idaho (ID)", "Illinois (IL)",
    "Indiana (IN)", "Iowa (IA)", "Kansas (KS)", "Kentucky (KY)", "Louisiana (LA)",
    "Maine (ME)", "Marshall Islands (MH)", "Maryland (MD)", "Massachusetts (MA)",
    "Michigan (MI)", "Minnesota (MN)", "Mississippi (MS)", "Missouri (MO)", "Montana (MT)",
    "Nebraska (NE)", "Nevada (NV)", "New Hampshire (NH)", "New Jersey (NJ)", "New Mexico (NM)",
    "New York (NY)", "North Carolina (NC)", "North Dakota (ND)", "Ohio (OH)", "Oklahoma (OK)",
    "Oregon (OR)", "Pennsylvania (PA)", "Rhode Island (RI)", "South Carolina (SC)",
    "South Dakota (SD)", "Tennessee (TN)", "Texas (TX)", "Utah (UT)", "Vermont (VT)",
    "Virginia (VA)", "Washington (WA)", "West Virginia (WV)", "Wisconsin (WI)",
    "Wyoming (WY)"]

  constructor(
    private auth: RoleService,
    private roleService: DirectoryRoleService,
    private errorHandler: UxService,
    private validator: ValidationService,
    private navService: NavService,
    private route: ActivatedRoute,
    private divisionService: DivisionService,
    private welcomeOrganizationService: WelcomeOrganizationService
  ) {
  }

  ngOnInit() {
    this.page = this.navService.register(`/practice/list/new`, this.route, (isCurrent, params) => {
      this.isCurrent = isCurrent;
      if (this.isCurrent) {
        this.setContext();
      }
    });
    this.practice = new Organization({})
  }

  add() {
    if (!this.practice.email) {
      this.errorHandler.handleError("Email is required")
      return
    }
    if (!this.practice.name) {
      this.errorHandler.handleError("Name is required")
      return
    }
    if (!this.practice.address.city) {
      this.errorHandler.handleError("City is required")
      return
    }
    if (!this.practice.address.location) {
      this.errorHandler.handleError("Practice location is required")
      return
    }
    if (this.practice.email) {
      if (this.validator.isEmailValid(this.practice.email)) {
        this.errorHandler.handleError("Invalid Email")
        return
      }
    }
    if (this.practice.phone) {
      if (this.validator.isMobileValid(this.practice.phone)) {
        this.errorHandler.handleError("Invalid Phone")
        return
      }
    }
    if (!this.practice.phone) {
      this.errorHandler.handleError("Phone is required")
      return
    } else {
      this.practice.phone = this.practice.phone.replace(/-/g, "")
    }
    if (this.practice.phone && this.practice.phone.length != 10) {
      this.errorHandler.handleError("Invalid Phone")
      return
    }
    if (this.practice.meta.fax) {
      this.practice.meta.fax = this.practice.meta.fax.replace(/-/g, "")
    }
    this.isProcessing = true
    let code = "practice" + new Date().getTime()
    this.roleService.create({
      organization: {
        name: this.practice.name,
        code: code,
        type: "practice",
        email: this.practice.email,
        phone: this.practice.phone,
        address: this.practice.address,
        meta: this.practice.meta,
        timezoneOffset: this.practice.timezoneOffset,
        logo: {
          "url": "https://openage.github.io/themes/oppos/v1/icons/placeholder/hospital-placeholder.svg"
        }
      },
      employee: {
        profile: this.auth.currentRole().profile,
        code: code
      },
      code: code
    }).subscribe((role) => {
      // this.auth.addRole(role)
      let meta = this.practice.meta || {}
      meta.email = this.practice.email
      meta.phone = this.practice.phone
      // if (this.welcomePractice.calendarID) {
      //   this.welcomeOrganizationService.update(role.organization.code, this.welcomePractice).subscribe((item) => {
      //     this.welcomePractice = new WelcomeOrganization(item)
      //   })
      // }
      this.divisionService.create({ code: this.practice.address.location, name: this.practice.address.location, meta: meta, address: this.practice.address, organization: role.organization }).subscribe(() => {
        this.errorHandler.showInfo("Practice Added")
        this.isProcessing = false
        this.navService.goto("/practice/list")
      }, err => {
        if (this.practice.meta.fax) {
          this.practiceFaxNumberFormat(this.practice.meta.fax)
        }
        this.phoneNumberFormat(this.practice.phone)
      })
    }, err => {
      if (this.practice.meta.fax) {
        this.practiceFaxNumberFormat(this.practice.meta.fax)
      }
      this.phoneNumberFormat(this.practice.phone)
    })
  }

  phoneNumberFormat(mobile) {
    if (!mobile) {
      return
    }
    let phoneNumber = mobile
    let phoneNumberArray
    phoneNumber = phoneNumber.replace(/[^0-9]*/g, '')
    if (phoneNumber) {
      phoneNumberArray = phoneNumber.split('')
      phoneNumber = ''
      phoneNumberArray.forEach((item, i) => {
        if (i < 2) { phoneNumber = phoneNumber + item }
        if (i == 2) { phoneNumber = phoneNumber + item + '-' }
        if ((i < 5) && (i > 2)) { phoneNumber = phoneNumber + item }
        if (i == 5) { phoneNumber = phoneNumber + item + '-' }
        if (i > 5 && i < 11) { phoneNumber = phoneNumber + item }
      });
    }
    mobile = phoneNumber
    return this.practice.phone = mobile
  }

  onlyNum(event) {
    if (isNaN(parseInt(event.key))) {
      return false;
    }
  }

  practiceFaxNumberFormat(mobile) {
    if (!mobile) {
      return
    }
    let phoneNumber = mobile
    let phoneNumberArray
    phoneNumber = phoneNumber.replace(/[^0-9]*/g, '')
    if (phoneNumber) {
      phoneNumberArray = phoneNumber.split('')
      phoneNumber = ''
      phoneNumberArray.forEach((item, i) => {
        if (i < 2) { phoneNumber = phoneNumber + item }
        if (i == 2) { phoneNumber = phoneNumber + item + '-' }
        if ((i < 5) && (i > 2)) { phoneNumber = phoneNumber + item }
        if (i == 5) { phoneNumber = phoneNumber + item + '-' }
        if (i > 5) { phoneNumber = phoneNumber + item }
      });
    }
    return this.practice.meta.fax = phoneNumber
  }

  setContext() {
  }

  back() {
    this.navService.goto("/practice/list")
  }

}
